import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function ContactInfo({
  faIcon,
  text,
  classStyleIcon = "",
  classStyleText = "",
  link,
}) {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <span
        style={{ transition: "0.4s ease" }}
        className={`text-gray-700 md:text-4xl xs:text-xl ${classStyleIcon}`}
      >
        <div className="inline-block">
          <FontAwesomeIcon icon={faIcon} className="mr-4" />
          <h2 className={`inline-block ${classStyleText}`}>{text}</h2>
        </div>
      </span>
    </a>
  )
}
