import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import React from "react"

export default function ContactForm() {
  const intl = useIntl()
  const emailPh = intl.formatMessage({ id: "contact.form.email.placeholder" })
  const msgPh = intl.formatMessage({ id: "contact.form.message.placeholder" })

  return (
    <div className="my-4 w-8/12 mx-auto background-contact">
      <h2 className="text-xl text-gray-700 font-semibold">
        <FormattedMessage id="contact.writeme" />
      </h2>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        className="rounded pt-6 pb-8 mb-4"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            <FormattedMessage id="contact.form.email.label" />
            <input
              className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              name="email"
              type="text"
              placeholder={emailPh}
            />
          </label>
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="message"
          >
            <FormattedMessage id="contact.form.message.label" />
            <textarea
              className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline md:h-auto xs:h-24"
              id="message"
              name="message"
              placeholder={msgPh}
            ></textarea>
          </label>
          <p className="text-green-500 text-xs italic">
            <FormattedMessage id="contact.form.secondmessage" />
          </p>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            <FormattedMessage id="contact.form.submit" />
          </button>
        </div>
      </form>
    </div>
  )
}
