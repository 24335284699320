import {
  faGithub,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import React from "react"
import ContactInfo from "../components/ContactInfo"

export default function SocialNetworks() {
  return (
    <div>
      <section>
        <br />
        <div className="flex flex-wrap flex-col">
          <div className="mb-5 py-4 background-contact">
            <ContactInfo
              faIcon={faTwitter}
              text={"@ogastonc"}
              classStyleIcon={"hover:text-blue-500"}
              link={"https://twitter.com/ogastonc"}
            />
          </div>
          <div className="mb-5 py-4 background-contact">
            <ContactInfo
              faIcon={faLinkedin}
              text={"Omar Gastón Chalas"}
              classStyleIcon={"hover:text-blue-700"}
              link={"https://www.linkedin.com/in/omar-gaston-chalas/"}
            />
          </div>
          <div className="mb-5 py-4 background-contact">
            <ContactInfo
              faIcon={faGithub}
              text={"ogaston"}
              classStyleIcon={"hover:text-gray-900"}
              link={"https://github.com/ogaston"}
            />
          </div>
        </div>
      </section>
    </div>
  )
}
