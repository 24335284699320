import React from "react"
import SEO from "../components/seo"
import Layout from "../containers/Layout"
import SocialNetworks from "../sections/SocialNetworks"
import ContactForm from "../sections/ContactForm"
import { Container } from "../components/Container"
import { FormattedMessage } from "gatsby-plugin-intl"
import ScatterCircle from "../assets/scatter-circle.svg"

const Contact = () => (
  <Layout>
    <SEO intlId="contact" />
    <Container>
      <div className="w-full">
        <h1 className="text-3xl font-semibold">
          <FormattedMessage id="contact.title" />
        </h1>
      </div>
      <p>
        <FormattedMessage id="contact.text" />
      </p>
      <section className="xs:block md:flex mt-8">
        <div className="flex-1">
          <SocialNetworks />
        </div>
        <div className="flex-1">
          <small
            className="right-0"
            style={{
              zIndex: -1,
              position: "absolute",
              width: "35%",
              top: "95px",
              right: "10%",
            }}
          >
            <ScatterCircle className="xs:w-24 md:w-full pl-12" />
          </small>
          <div className="py-12 xs:w-full md:w-8/12 mx-auto bg-gray-100">
            <ContactForm />
          </div>
        </div>
      </section>
    </Container>
  </Layout>
)

export default Contact
